import { Typography } from '@mui/material';
import React from 'react';

const NotFoundPage = () => {
    return (
        <Typography variant='h4' color='primary' className='w-100 min-h-100-vh d-flex justify-content-center align-items-center'>
           The Page is Not Found 
        </Typography>
    )
}
export default NotFoundPage